import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Newsletter = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Newsletter</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="news-hero">
            <div class="hero-body">
            </div>
      </section>
      <main>
          <h1>Newsletter</h1>
          <p>Do you want to learn more about the KEEP Foundation and receive information about upcoming programs, and events? Sign up below to receive our monthly KEEP Foundation newsletter via email.</p>
          <div class="ctct-inline-form" data-form-id="b09a323c-6b5e-42fd-8eb3-82a4eb0de6e0"></div>
        </main>
    </Layout>
  )
}

export default Newsletter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`